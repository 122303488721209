.inputunit {
  max-width :350px;
  margin: 10px;
}

.inputunit .inputgrouptext{
  width: 75px;
  background-color: transparent;
  font-weight: bold;
  border: 0;
  overflow: hidden;
}

.inputunit .inputgroupinfo{
  background-color: transparent;
  font-weight: bold;
  border: 0;
  overflow: hidden;
}

.inputunit .inputgroupinfo .material-icons{
  color: dodgerblue;
  font-size: 20px;
  cursor: pointer;
}

.inputunit .input {
  color: blue;
  background-color: #C6DEFF;
  border: 0;
  border-radius: 0;
}

.inputunit .inputunits {
  /* width: 90px; */
  color: blue;
  background-color: #C6DEFF;
  border: 0;
  border-radius: 0;
}