#cardaddproject {
  border-radius: 0px;
  cursor: pointer;
  border: 0px solid #289dcc;
  box-shadow: 1px 1px 1px 1px #888888;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  /* padding: auto; */
  display: block;
  /* width: auto;
  height: auto; */
  /* max-height: 200px; */
}

#cardaddproject:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
