
#card #headerinputs{
  color:white;
  border-radius: 0;
  border: 0;
  text-align: center;
  background-color:midnightblue;
}

#sectionsDataOptions{
  min-height:400px;
}

#formgroupcard label{
  padding-top:7px;
}
