.sectionintext {
  width: 100%;
}

.sectionintext .button{
  border-radius:0px;
  margin: 0px;
  padding: 0;
  width: 100%;
  /* border: 1px solid dodgerblue;
  background-color:transparent; 
  color:dodgerblue; */
}

.column .button{
  border: 0;
  background-color: transparent;
  color: black;
} 

.itemtable {
  max-width: 60px;
  border: 0;
  margin: 0;
  text-align: center;
}